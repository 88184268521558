import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import styled from "styled-components"

import Layout from "../components/layout"

const stepdata = [
  {
    number: 1,
    title: "Inspectie",
    description:
      "De staat van het meubel wordt bekeken en beoordeelt welke voorbehandeling nodig is.",
  },
  {
    number: 2,
    title: "Voorbehandeling",
    description:
      "Voorbehandeling van hardnekkige vlekken met een vlek- verwijderaar. We kunnen hier gebruik maken van verschillende producten al naar gelang de soort vlek.",
  },
  {
    number: 3,
    title: "Insproeien",
    description:
      "Het gehele meubel wordt besproeid met een reinigingsmiddel. Dit middel laten we intrekken of wordt in geborsteld. Hierdoor weken vlekken los, doordat vuil zich zal hechten aan het reinigingsmiddel.",
  },
  {
    number: 4,
    title: "Extractie",
    description:
      "Door middel van onze professionele sproei-extractie machine wordt dmv een waterstraal het reinigingsmiddel met het aangehechte vuil losgeweekt en vervolgens direct opgezogen.",
  },
]

const Method = () => (
  <Layout id="werkwijze">
    <Container>
      <ImageContainer>
        <ImageWrapper>
          <StaticImage src="../images/interior-1.png" alt="A dinosaur" />
        </ImageWrapper>
        <ImageWrapper>
          <StaticImage src="../images/interior-2.png" alt="A dinosaur" />
        </ImageWrapper>
      </ImageContainer>
      <ContentContainer>
        <h2>Hoe gaat Interior Clean te werk?</h2>
        <Subtitle>
          Het reinigen van uw meubel wordt in vier stappen uitgevoerd:
        </Subtitle>
        <Steps>
          {stepdata.map(step => (
            <li key={step.number}>
              <StepHeader>
                <StepNumber>{step.number}</StepNumber>
                <h4>{step.title}</h4>
              </StepHeader>
              <p>{step.description}</p>
            </li>
          ))}
        </Steps>
      </ContentContainer>
    </Container>
  </Layout>
)

export default Method

const Container = styled.div`
  @media (min-width: 600px) {
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 48px;
  }
`

const ImageContainer = styled.div`
  display: none;

  @media (min-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const ImageWrapper = styled.div`
  border-radius: 20px;
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.15);
  width: 80%;

  img {
    border-radius: 20px;
  }

  &:nth-of-type(even) {
    margin: 0;
    margin-top: -15%;
    align-self: flex-end;
  }
`

const ContentContainer = styled.div`
  max-width: 470px;
`

const Subtitle = styled.p`
  font-size: 1.25rem;
  line-height: 1.15;
  color: #a6a6a6;
  font-weight: 500;

  margin-bottom: 24px;
`

const Steps = styled.ol`
  list-style: none;
  padding: 0;

  li {
    display: flex;
    flex-direction: column;

    margin-bottom: 24px;
  }

  p {
    margin-top: 0;
  }
`

const StepHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  h4 {
    margin: 0;
  }
`

const StepNumber = styled.span`
  font-size: var(--font-size-large);
  font-weight: bold;
  color: var(--color-primary);
  line-height: 1;

  margin-right: 16px;
`

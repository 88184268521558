/* eslint-disable react/prop-types */
import * as React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import { StyledButtonLink } from "../components/generic"

const Services = () => {
  const servicedata = useStaticQuery(
    graphql`
      query {
        allPricelistCsv {
          edges {
            node {
              price
              per
              name
              category
            }
          }
        }
      }
    `
  )

  const [services, setServices] = React.useState({})

  React.useEffect(() => {
    const oldServices = { ...services }
    servicedata.allPricelistCsv.edges.forEach(service => {
      if (
        Object.prototype.hasOwnProperty.call(oldServices, service.node.category)
      ) {
        if (!oldServices[service.node.category].includes(service.node))
          oldServices[service.node.category].push(service.node)
      } else {
        oldServices[service.node.category] = [service.node]
      }
    })
    setServices(oldServices)
  }, [servicedata])

  return (
    <Container id="diensten">
      <Layout>
        <Title>Diensten</Title>
        <StyledList>
          {Object.keys(services).map(key => {
            return (
              <CategoryContainer key={key}>
                <CategoryTitle>{key}</CategoryTitle>
                {services[key].map(service => (
                  <Service
                    key={service.name + service.price}
                    name={service.name}
                    price={service.price}
                    per={service.per}
                  />
                ))}
              </CategoryContainer>
            )
          })}
        </StyledList>
        <CallToAction>
          <h4>Heeft u vragen of wilt u een offerte aanvragen?</h4>
          <StyledButtonLink href="#contact">
            Neem contact met ons op
          </StyledButtonLink>
        </CallToAction>
      </Layout>
    </Container>
  )
}

export default Services

const Container = styled.div`
  margin: 96px 0;
  padding: 96px 0;
  background-color: #fafafa;

  & ${Layout} {
    margin: 0 auto;
  }
`

const Title = styled.h2`
  margin-bottom: 24px;

  @media (min-width: 700px) {
    text-align: center;
    margin-bottom: 48px;
  }
`

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;

  list-style: none;

  max-width: 500px;
  margin: 0 auto;
  margin-bottom: -32px;
  padding: 0;

  li {
    width: 100%;
  }
`

const CategoryContainer = styled.li`
  margin-bottom: 32px;
`

const CategoryTitle = styled.h4`
  font-size: 1.125rem;
  margin-top: 0;
  margin-bottom: 12px;
  color: var(--color-primary);
`

const Service = ({ name, price, per }) => (
  <ServiceContainer>
    <DetailContainer>
      <p>{name}</p>
      {per && <Quantity>per {per}</Quantity>}
    </DetailContainer>
    <Price>€{price}</Price>
  </ServiceContainer>
)

const ServiceContainer = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 12px;

  &:last-of-type {
    margin-bottom: 0;
  }

  p {
    line-height: 1.25;
  }
`

const DetailContainer = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }
`

const Price = styled.p`
  font-weight: bold;
  color: #595959;

  margin: 0;
  margin-left: 24px;

  line-height: 1.1875;
  font-size: 18px;
`

const Quantity = styled.span`
  font-size: 12px; // Needs replacement
  font-weight: 700;
  color: #595959;
`

const CallToAction = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 964px;
  padding: 24px;
  margin: 0 auto;
  margin-top: 42px;

  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.15);

  button {
    margin: 0;
  }

  h4 {
    font-size: 1.25rem;
    margin: 0;
    margin-bottom: 24px;
  }

  @media (min-width: 46rem) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-top: 64px;

    h4 {
      margin: 0;
    }
  }
`

import * as React from "react"
import styled from "styled-components"
import IntroBlob from "../components/introblob"
import Layout, { LayoutFullBleed } from "../components/layout"
import { StyledButtonLink } from "../components/generic"
import { StaticImage } from "gatsby-plugin-image"
import logo from "../images/logo.svg"

const Intro = () => {
  return (
    <LayoutFullBleed>
      <Header>
        <Layout>
          <HeaderContent>
            <img src={logo} />
            <Navigation>
              <li><a href="#diensten">Diensten</a></li>
              <li><a href="#werkwijze">Werkwijze</a></li>
              <li><a href="#contact">Contact</a></li>
            </Navigation>
          </HeaderContent>
        </Layout>
      </Header>
      <Layout>
        <IntroBlob />
        <IntroContainer>
          <IntroContent>
            <h1>Uw interieur weer als nieuw!</h1>
            <p>
              Interior Clean komt bij u thuis en reinigt uw interieur met milieu
              vriendelijke middelen en professionele apparatuur zodat deze weer
              jaren meekan.
            </p>
            <StyledButtonLink href="#contact">
              Neem contact met ons op
            </StyledButtonLink>
          </IntroContent>
          <ImageWrapper>
            <StaticImage src="../images/sofa.jpg" alt="A dinosaur" />
          </ImageWrapper>
        </IntroContainer>
      </Layout>
    </LayoutFullBleed>
  )
}

export default Intro

const Header = styled.div`
  position: absolute;
  width: 100%;

  z-index: 999;

  ${Layout} {
    margin-top: 0;
  }
`

const HeaderContent = styled.div`
  display: flex;
  padding: 27px 0;

  justify-content: space-between;
  align-items: center;
`

const Navigation = styled.ul`
  display: flex;
  gap: 32px;
  list-style: none;

  font-weight: bold;

  border-bottom: 2px solid transparent;

  a {
    color: inherit;
    text-decoration: none;
  }

  a:active {
    color: var(--color-primary);
  }

  &:hover {
    cursor: pointer;
  }

  li:hover {
    border-bottom: 2px solid var(--color-primary)
  }

  & li:not(:first-child) {
    display: none;

    @media (min-width: 600px) {
      display: block;
    }
  }
`

const ImageWrapper = styled.div`
  max-height: 900px;

  margin-top: 48px;

  border-radius: 20px;
  overflow: hidden;

  filter: drop-shadow(2px 4px 8px rgba(0, 0, 0, 0.15));

  @media (min-width: 600px) {
    margin-top: 0;
    margin-left: -50%;
  }

  .gatsby-image-wrapper {
    border-radius: 20px;
    height: 100%;
  }
`

const IntroContainer = styled.div`
  padding: 0 0 32px;

  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 48px;
  }
`

const IntroContent = styled.div`
  max-width: 470px;

  p {
    margin-bottom: 48px;
  }

  @media (min-width: 600px) {
    order: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`

import styled from "styled-components"

const Layout = styled.div`
  position: relative;
  padding: 0 16px;
  margin: 96px auto 0;
  max-width: 1200px;
`
export default Layout

export const LayoutFullBleed = styled.div`
  position: relative;
  overflow-x: hidden;

  & > ${Layout} {
    margin-top: 116px;
  }
`

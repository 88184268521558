import styled from "styled-components"

export const StyledButton = styled.button`
  border: none;
  font: inherit;
  font-weight: bold;
  background-color: var(--color-primary);
  border-radius: 10px;
  padding: 12px 24px;
  color: white;
  box-shadow: 0 10px 25px rgb(30 123 234 / 50%);
  margin: 24px 0;

  user-select: none;
  -webkit-tap-highlight-color: transparent; // Removing the on-tap blinking in safari

  transition: transform 0.2s cubic-bezier(0, 0, 0.3, 1.6);

  &:hover {
    transform: translate(0, 2px);
    cursor: pointer;
  }

  &:active {
    transform: translate(0, 5px);
  }
`

export const StyledButtonLink = styled.a`
  display: inline-block;
  border: none;
  font: inherit;
  font-weight: bold;
  background-color: var(--color-primary);
  text-decoration: none;
  border-radius: 10px;
  padding: 12px 24px;
  color: white;
  box-shadow: 0 10px 25px rgb(30 123 234 / 50%);
  text-align: center;

  user-select: none;
  -webkit-tap-highlight-color: transparent; // Removing the on-tap blinking in safari

  transition: transform 0.2s cubic-bezier(0, 0, 0.3, 1.6);

  &:hover {
    transform: translate(0, 2px);
    cursor: pointer;
  }

  &:active {
    transform: translate(0, 5px);
  }
`

import * as React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"

import GlobalStyles from "../components/global"
import Seo from "../components/seo"
import Why from "../sections/Why"
import Method from "../sections/Method"
import Services from "../sections/Services"
import Intro from "../sections/Intro"
import Contact from "../sections/Contact"

const currentYear = new Date().getFullYear()

const Home = () => (
  <>
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Karla:wght@400;500;700&display=swap"
        rel="preload"
        as="style"
      />
    </Helmet>
    <GlobalStyles />
    <Seo title="Interior Clean" />
    <Intro />
    <Why />
    <Services />
    <Method />
    <Contact />
    <Footer>&copy; {currentYear} - Interior Clean</Footer>
  </>
)

const Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 48px;

  font-weight: bold;

  color: #fff;
  background-color: var(--color-primary);
`

export default Home

import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
  html {
    // Typography
    --font-type-scale: 1.25; // Major thirds
    --font-size-base: 1rem;
    --font-size-small: calc(var(--font-size-base) * var(--font-type-scale));
    --font-size-medium: calc(var(--font-size-small) * var(--font-type-scale));
    --font-size-large: calc(var(--font-size-medium) * var(--font-type-scale));
    --font-size-extra-large: calc(var(--font-size-large) * var(--font-type-scale));

    @media (min-width: 1024px) {
      --font-size-small: calc(calc(var(--font-size-base) * var(--font-type-scale)) * var(--font-type-scale));
    }

    // Colors
    --color-primary: #1E7BEA;

    scroll-behavior: smooth;
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }

  body {
    padding: 0;
    margin: 0;
    font-family: 'Karla', sans-serif;
    text-size-adjust: 100%;
    line-height: 1.75;
    color: #262626;
    -webkit-font-smoothing: antialiased;
  }

  h1, h2, h3, h4 {
    font-weight: bold;
    line-height: 1.17;
    margin-top: 0;
    margin-bottom: 24px;
  }

  h1 {
    font-size: var(--font-size-extra-large);
    margin: 1.5rem 0;
  }

  h2 {
    font-size: var(--font-size-large);
  }

  h3 {
    font-size: var(--font-size-medium);
  }

  h4 {
    font-size: var(--font-size-small);
  }
`

export default GlobalStyles

import * as React from "react"
import styled from "styled-components"
import background from "../images/background-contact.svg"

import Layout from "../components/layout"
import { StyledButton } from "../components/generic"
import Icon from "../components/icon"

const Contact = () => {
  const [state, setState] = React.useState({})
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
    .then((response) => {
      if (response.ok) {
        setErrorMessage('');
        setSuccessMessage('We hebben uw bericht ontvangen en zullen zo spoedig mogelijk contact met u opnemen!');
        form.reset();
      } else {
        setSuccessMessage('');
        setErrorMessage('Er is iets mis gegaan tijdens het verzenden van uw bericht, probeer het later nogmaals.');
      }
    })
    .catch(error => {
      setErrorMessage('Er is iets mis gegaan tijdens het verzenden van uw bericht, probeer het later nogmaals.');
      console.error(error);
    })
  }

  return (
    <Container id="contact">
      <Layout>
        <Content>
          <Info>
            <h2>Contact</h2>
            <p>
              Neem contact met ons op of vul het contactformulier in en wij zullen zo snel mogelijk contact met u opnemen.
            </p>
            <Communication>
              <li>
                <Icon type="phone" inverted/>
                <p>06 - 12345678</p>
              </li>
              <li>
                <Icon type="mail" inverted/>
                <p>info@interiorclean.nl</p>
              </li>
            </Communication>
          </Info>
          <Form
            name="contact"
            id="form"
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            <HiddenField>
              <label>Come here botty, hehe: <input onChange={handleChange} name="bot-field"/></label>
            </HiddenField>
            <InputContainer>
              <label htmlFor="name">Uw naam (verplicht)</label>
              <input
                type="text"
                name="name"
                id="name"
                onChange={handleChange}
                required
              />
            </InputContainer>
            <InputContainer>
              <label htmlFor="email">Uw mail (verplicht)</label>
              <input
                type="email"
                name="email"
                id="email"
                onChange={handleChange}
                required
              />
            </InputContainer>
            <MessageContainer>
              <label htmlFor="message">Bericht (verplicht)</label>
              <MessageInfo>Geef eventueel meteen aan welke diensten u wilt afnemen.</MessageInfo>
              <textarea
                name="message"
                id="message"
                onChange={handleChange}
                required
              />
            </MessageContainer>
            <DataInfo>Dit formulier vraagt u om uw naam en email adres zodat wij met u kunnen communiceren.</DataInfo>
            <ButtonContainer>
              <StyledButton type="submit">Verstuur bericht</StyledButton>
            </ButtonContainer>
            {successMessage && (
              <SuccessMessage>{successMessage}</SuccessMessage>
            )}
            {errorMessage && (
              <ErrorMessage>{errorMessage}</ErrorMessage>
            )}
          </Form>
        </Content>
      </Layout>
    </Container>
  )
}

export default Contact

const HiddenField = styled.p`
  visibility: hidden;
  position: absolute;
`

const StatusMessage = styled.p`
  margin: 0;
  font-size: 12px;
`

const SuccessMessage = styled(StatusMessage)`
  color: #409D44;
`

const ErrorMessage = styled(StatusMessage)`
  color: #E26352;
`

const Container = styled.div`
  background: url(${background});
  background-size: cover;
  background-position: center;

  margin-top: 96px;
  padding: 96px 0;

  & ${Layout} {
    margin: 0 auto;
  }
`

const Content = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  row-gap: 24px;

  @media (min-width: 900px) {
    display: grid;
    grid-template-columns: 1fr minmax(500px, 1fr);
    column-gap: 48px;
  }
`

const Info = styled.div`
  max-width: 470px;
  
  color: #fff;
`

const Communication = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 24px;

  margin-top: 64px;

  list-style: none;

  li {
    display: flex;
    align-items: center;
  }

  p {
    margin: 0;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;

  gap: 24px;
  padding: 24px;

  background-color: #ffffff;
  border-radius: 20px;

  @media (min-width: 1000px) {
    padding: 48px;
  }

  label {
    font: inherit;
    font-weight: bold;
  }
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  input {
    border: 2px solid #e6e6e6;
    border-radius: 10px;
    padding: 10px 16px;

    font-family: inherit;
    font-size: inherit;

    &:focus-visible {
      border-color: var(--color-primary);
      outline: none;
    }
  }
`

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;

  flex-basis: 100%;

  textarea {
    border: 2px solid #f2f2f2;
    border-radius: 10px;
    padding: 10px 16px;

    background-color: #f2f2f2;

    font-family: inherit;
    font-size: inherit;

    max-width: 100%;

    &:focus-visible {
      border-color: var(--color-primary);
      outline: none;
    }

    resize: vertical;
    min-height: 200px;
  }
`

const MessageInfo = styled.p`
  margin: 4px 0 8px;

  font-size: 14px;

  color: #4D4D4D;
`

const DataInfo = styled.p`
  margin: 0;

  font-size: 12px;
  font-style: italic;
  
  color: #4D4D4D;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;

  ${StyledButton} {
    margin: 0;
    
    @media (max-width: 899px) {
      flex: 1;
    }
  }
`
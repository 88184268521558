import * as React from "react"
import styled from "styled-components"

import Icon from "../components/icon"
import Layout from "../components/layout"

const Why = () => (
  <Layout>
    <Container>
      <ContentContainer>
        <h2>Waarom interior clean?</h2>
        <p>
          Na verloop van tijd ontstaan er vlekken in uw bankstel en zou u deze
          het liefst de deur uit doen en op zoek gaan naar een nieuw zitmeubel.
          Een kostbare en ook vaak onnodige beslissing.
        </p>
        <p>
          Interior Clean komt bij u thuis en reinigt uw bankstel met milieu
          vriendelijke middelen en professionele apparatuur zodat deze weer
          jaren meekan. Goed voor uw portemonnee en het milieu. Erg belangrijk
          in deze tijd van verspillen en weggooien.
        </p>
      </ContentContainer>
      <ContentContainer>
        <USPBlock>
          <ul>
            <li>
              <Icon type="location" />
              Reiniging op locatie
            </li>
            <li>
              <Icon type="environment" />
              Millieuvriendelijke producten
            </li>
            <li>
              <Icon type="price" />
              Duidelijke tarieven, zonder verborgen kosten
            </li>
            <li>
              <Icon type="time" />
              Uw stoel, bank en matras gaan langer mee en zijn weer fris
            </li>
          </ul>
        </USPBlock>
      </ContentContainer>
    </Container>
  </Layout>
)

export default Why

const Container = styled.div`
  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 48px;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 470px;
`

const USPBlock = styled.div`
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 16px;
  max-width: 470px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  li:last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: 600px) {
    padding: 48px;
  }
`

import * as React from "react"
import styled from "styled-components"
import Blob from "./blob"

const IntroBlob = () => (
  <>
    <BlobOne>
      <Blob />
    </BlobOne>
    <BlobTwo>
      <Blob />
    </BlobTwo>
  </>
)

export default IntroBlob

const BlobOne = styled.div`
  position: absolute;
  width: 50%;
  min-width: 400px;

  right: 0;
  top: 0;

  transform: translate(5%, -30%) rotate(45deg);

  svg {
    width: 100%;
    height: auto;

    opacity: 0.1;
  }
`

const BlobTwo = styled.div`
  position: absolute;
  width: 55%;
  min-width: 500px;

  right: 0;
  top: 0;

  transform: translate(50%, -25%) rotate(105deg);

  svg {
    width: 100%;
    height: auto;

    opacity: 0.1;
  }
`

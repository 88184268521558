import * as React from "react"

const Blob = () => (
  <svg width="145" height="139" viewBox="0 0 145 139">
    <path
      d="M106.599 20.4588C116.099 29.7588 123.299 40.3588 131.499 55.3588C139.599 70.2588 148.599 89.7588 142.499 100.659C136.399 111.659 115.299 114.159 95.7987 121.359C76.3987 128.559 58.4987 140.359 41.9987 138.659C25.3987 136.959 10.0987 121.759 3.79868 104.359C-2.60132 87.0588 -0.101315 67.6588 5.29868 50.7588C10.5987 33.8588 18.7987 19.3588 30.6987 10.5588C42.5987 1.65883 58.2987 -1.64117 71.8987 0.75883C85.4987 3.15883 97.1987 11.2588 106.599 20.4588Z"
      fill="#1E7BEA"
    />
  </svg>
)

export default Blob
